import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'napa-search-icon',
  standalone: true,
  templateUrl: './search-icon.component.html',
  styleUrl: './search-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchIconComponent {}
