import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route, Router } from '@angular/router';
import { AUTH_RC, AUTHORIZED_RC } from '@core/constants/routes.constants';
import { EMPTY, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PreloadingStrategyService implements PreloadingStrategy {
  constructor(private router: Router) {}

  preload(route: Route, load: () => Observable<unknown>): Observable<unknown> {
    if (route.path === AUTHORIZED_RC.root) {
      return this.loadAuthorizedContentModule(load);
    }

    return load();
  }

  private loadAuthorizedContentModule(load: () => Observable<unknown>): Observable<unknown> {
    if (this.router.url.includes(AUTH_RC.children.logIn)) {
      return load();
    }

    return EMPTY;
  }
}
