import { WlcmIconName, WlcmIcons } from '@wlcm/angular/core';
import { ArrowDownBlueIconComponent } from '../components/icons/arrow-down-blue-icon/arrow-down-blue-icon.component';
import { CalendarIconComponent } from '../components/icons/calendar-icon/calendar-icon.component';
import { SearchIconComponent } from '@shared/components/icons/search-icon/search-icon.component';

export const WLCM_ICONS_CONFIG: WlcmIcons = {
  [WlcmIconName.SEARCH]: SearchIconComponent,
  [WlcmIconName.CALENDAR]: CalendarIconComponent,
  [WlcmIconName.CHEVRON_DOWN]: ArrowDownBlueIconComponent,
};
