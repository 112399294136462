import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { SnackbarApiService } from '../helper/snackbar-api.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private snackbarApi: SnackbarApiService) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => this.handleApiError<T>(error)));
  }

  private handleApiError<T>(error: HttpErrorResponse): Observable<HttpEvent<T>> {
    if (
      error.status === HttpStatusCode.NotFound ||
      error.status === HttpStatusCode.BadRequest ||
      error.status === HttpStatusCode.PayloadTooLarge ||
      error.status === HttpStatusCode.Conflict
    ) {
      this.showErrorMessage(error);
    }

    return throwError(() => error);
  }

  private showErrorMessage(error: HttpErrorResponse) {
    if (typeof error.error.message === 'string') {
      return this.snackbarApi.failure(error.error.message);
    }
    return this.snackbarApi.failure(error.statusText);
  }
}
