import { isDevMode, NgModule } from '@angular/core';
import { StoreModule as NGRXStoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers } from '@store/local-store-sync.config';
import { rootReducers } from './root-store';

@NgModule({
  imports: [
    EffectsModule.forRoot([]),
    NGRXStoreModule.forRoot(rootReducers, { metaReducers, runtimeChecks: { strictStateImmutability: false } }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
  ],
})
export class StoreModule {}
