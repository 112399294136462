import { Injectable } from '@angular/core';
import { FAMILIES_RC, SESSIONS_RC } from '@core/constants/routes.constants';
import { Role } from '@core/constants';

const DEFAULT_ROUTE_MAP: { [key in Role]: string } = {
  [Role.ADMIN]: SESSIONS_RC.root,
  [Role.SUPER_ADMIN]: SESSIONS_RC.root,
  [Role.COORDINATOR]: SESSIONS_RC.root,
  [Role.PARENT]: `${FAMILIES_RC.root}/${FAMILIES_RC.children.own}`,
};

@Injectable({ providedIn: 'root' })
export class DefaultRouteApi {
  resolveDefaultRoute(role: Role): string {
    return DEFAULT_ROUTE_MAP[role];
  }
}
