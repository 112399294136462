import { ChangeDetectionStrategy, Component, Inject, ViewContainerRef } from '@angular/core';
import { APP_VERSION } from '@core/constants/platform/app-version.token';
import { ENVIRONMENT } from '@core/constants/platform/environment.token';
import { Environment, EnvironmentName } from '@core/models/environment.models';

@Component({
  selector: 'napa-version-renderer',
  templateUrl: './version-renderer.component.html',
  styleUrls: ['./version-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionRendererComponent {
  constructor(
    private viewContainerRef: ViewContainerRef,
    @Inject(ENVIRONMENT) environment: Environment,
    @Inject(APP_VERSION) public appVersion: string
  ) {
    if (environment.name === EnvironmentName.PROD) {
      this.viewContainerRef.clear();
    }
  }
}
