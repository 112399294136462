import { NgModule, Provider, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { WLCM_ERRORS } from '@wlcm/angular/forms';

import { FORM_ERRORS } from './constants/forms/form-errors.constants';
import { VersionRendererComponent } from '@core/components/version-renderer/version-renderer.component';
import { ApiUrlnterceptor } from '@core/services/interceptors/api-url.interceptor';
import { ErrorInterceptor } from '@core/services/interceptors/error.interceptor';
import { ApiTokenInterceptor } from '@core/services/interceptors/api-token.interceptor';
import { BlockScrollStrategy, Overlay, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_DIALOG_SCROLL_STRATEGY } from '@angular/material/dialog';
import { WLCM_ICONS_CONFIG } from '@shared/constants/icons.constants';
import { WLCM_DATEPICKER_CONFIG } from '@wlcm/angular/datepicker';
import { WLCM_ICONS } from '@wlcm/angular/core';
import { UnsavedChangesGuard } from './guards/unsaved-changes.guard';
import { DialogModule } from '@shared/modules/dialog/dialog.module';

export function blockDialogScrolling(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

const material: Provider[] = [
  {
    provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
    useValue: { verticalPosition: 'bottom', horizontalPosition: 'end' },
  },
  {
    provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    useValue: { position: 'above' },
  },
  { provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: () => inject(ScrollStrategyOptions).reposition() },
  {
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  },
  {
    provide: MAT_DIALOG_SCROLL_STRATEGY,
    useFactory: blockDialogScrolling,
    deps: [Overlay],
  },
];

@NgModule({
  declarations: [VersionRendererComponent],
  imports: [CommonModule, HttpClientModule, DialogModule],
  exports: [VersionRendererComponent],
  providers: [
    ...material,
    { provide: WLCM_ERRORS, useValue: FORM_ERRORS },
    { provide: WLCM_ICONS, useValue: WLCM_ICONS_CONFIG },
    { provide: WLCM_DATEPICKER_CONFIG, useValue: { selectOnly: true } },
    { provide: HTTP_INTERCEPTORS, useClass: ApiUrlnterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiTokenInterceptor, multi: true },
    UnsavedChangesGuard,
  ],
})
export class CoreModule {}
