import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { AuthApi } from '../api/auth.api';

@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {
  constructor(private authApi: AuthApi) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next
      .handle(this.addAccessToken(request))
      .pipe(catchError((error: HttpErrorResponse) => this.handleApiError<T>(error, request, next)));
  }

  private handleApiError<T>(
    error: HttpErrorResponse,
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    if (error.status === HttpStatusCode.Unauthorized && !/logout$/.test(error.url || '')) {
      if (this.authApi.getRefreshToken()) {
        return this.refreshToken(request, next);
      }
      this.authApi.logOut().subscribe();
    }

    return throwError(() => error);
  }

  private refreshToken<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return this.authApi.refreshToken().pipe(
      switchMap(({ accessToken }) => {
        const transformedRequest: HttpRequest<T> = this.addAccessToken<T>(request, accessToken);
        return next.handle(transformedRequest);
      }),
      catchError((error) => {
        this.authApi.logOut().subscribe();
        return throwError(() => error);
      })
    );
  }

  private addAccessToken<T>(request: HttpRequest<T>, token?: string): HttpRequest<T> {
    const accessToken: string | null = token || this.authApi.getAccessToken();
    return request.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } });
  }
}
