import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { SnackbarApiService } from '../helper/snackbar-api.service';
import { HttpUtils } from '@core/utils/http.utils';
import { Observable, tap } from 'rxjs';
import { ENVIRONMENT } from '@core/constants/platform/environment.token';
import { Environment } from '@core/models/environment.models';

@Injectable()
export class ApiUrlnterceptor implements HttpInterceptor {
  constructor(
    private snackbarApi: SnackbarApiService,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    let headers: HttpHeaders = request.headers;

    if (headers.has(HttpUtils.skipSuccessMessageHeader)) {
      headers = headers.delete(HttpUtils.skipSuccessMessageHeader);
    }

    return next
      .handle(request.clone({ url: `${this.environment.apiUrl}/${request.url}`, headers }))
      .pipe(tap((event: HttpEvent<T>) => this.handleSuccessEvent(request, event)));
  }

  private handleSuccessEvent<T>(request: HttpRequest<unknown>, event: HttpEvent<T>): void {
    if (event instanceof HttpResponse && event.ok) {
      if (request.headers.has(HttpUtils.skipSuccessMessageHeader)) return;

      if (request.method === 'PATCH' || request.method === 'POST' || request.method === 'DELETE') {
        this.snackbarApi.success();
      }
    }
  }
}
