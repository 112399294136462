import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AUTHORIZED_RC } from '@core/constants/routes.constants';
import { ProfileApi } from '@core/services/api/profile.api';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IntakeSurveyGuard {
  constructor(
    private profileApi: ProfileApi,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const isIntakeSurvey = new RegExp(AUTHORIZED_RC.intakeSurvey.root).test(state.url);

    return this.profileApi.user$.pipe(
      map(() => {
        if (!this.profileApi.isIntakeFormCompleted() && !isIntakeSurvey) {
          this.router.navigate([AUTHORIZED_RC.intakeSurvey.root]);
          return false;
        }
        return true;
      })
    );
  }
}
