import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { User } from '@core/models/entities/user.models';
import { ProfileApi } from '@core/services/api/profile.api';
import { DefaultRouteApi } from '@core/services/helper/default-route.api';
import { filter, map, Observable, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DefaultRouteGuard {
  constructor(
    private router: Router,
    private profileApi: ProfileApi,
    private defaultRouteApi: DefaultRouteApi
  ) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (state.url.split('/').filter(Boolean).length > 0) return true;

    return this.profileApi.user$.pipe(filter(Boolean), take(1)).pipe(
      map((user: User) => {
        const route: string = this.defaultRouteApi.resolveDefaultRoute(user.role);

        this.router.navigateByUrl(route);

        return true;
      })
    );
  }
}
