<svg
  width="16px"
  height="16px"
  viewBox="0 0 16 16"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="icon/search" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
    <circle id="Oval" stroke="#1A7DB8" stroke-width="2" cx="7.5" cy="7.5" r="6.5"></circle>
    <line
      x1="12.5"
      y1="12.5"
      x2="15"
      y2="15"
      id="Path-3"
      stroke="#1A7DB8"
      stroke-width="2"
      stroke-linecap="round"
    ></line>
  </g>
</svg>
